import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'


/* 重置浏览器样式 */
import './css/reset.css'


//全部引入
import * as echarts from 'echarts';
import "echarts-extension-amap";
// // 按需引入
// import * as echarts from 'echarts/lib/echarts';

//以下函数,是用在在main.js里面的
//通过Vue.prototype 将echarts保存为全局变量，才能真正使用
Vue.prototype.$echarts = echarts


// main.js
// 在main.js中引入 作为全局
import seamlessScroll from 'vue-seamless-scroll'

Vue.use(seamlessScroll)

// 封装数组对象排序方法
Array.prototype.compare = function (prop) {
    return function (a, b) {
        return b[prop] - a[prop]  // 降序
        //  return a[prop] - b[prop]  // 升序
    }
}

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
